import React, { Component } from "react";

import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";

import AllPosts from "../Pages/all-posts";
import ExitIntent from "../Pages/exit-intent";
import OnePost from "../Pages/one-post";
import { Link } from "react-router-dom";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import logoWhite from "../static/images/v-logo-white.png";
import HorizontalLine from "../static/images/horizontal-line.svg";
import MobileSide from "../static/images/mobile-side-img.svg";
import Card1 from "../static/images/card-1.png";
import Card3 from "../static/images/card-3.png";
import Card4 from "../static/images/card-4.png";
import Card5 from "../static/images/card-5.png";
import Card6 from "../static/images/card-6.png";
import Card7 from "../static/images/card-7.png";
import Card8 from "../static/images/card-8.png";
import Card2 from "../static/images/card-2.png";
import VideoIcon from "../static/images/video-icon.png";
import MessageIcon from "../static/images/message-icon.png";
import TabsIcon from "../static/images/tabs-icon.png";
import HomeImg from "../static/images/device-mockup.svg";
import HomeImgMobile from "../static/images/device-mockup-mobile-home.svg";
import WaveImg from "../static/images/wave-back.svg";
import FooterImg from "../static/images/footer-image.png";
import FooterImgMobile from "../static/images/footer-image-mobile.png";
import { useSpring, animated } from "react-spring";
import "./sales-screen.css";
import Slide from "react-reveal/Fade";
import WrappedHorizontalSignUpForm from "../Pages/sign-up";
import Header from "../Pages/Header";
import ChatBot from "../Pages/chat-bot";
import styled, { css, keyframes } from "styled-components";
import $ from "jquery";
const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse1 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse2 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse3 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse4 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse5 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const StyledButton = styled(Button)`
  animation: ${shadowPulse} 1s infinite;
`;
const StyledButton1 = styled(Button)`
  animation: ${shadowPulse1} 1s infinite;
`;
const StyledButton2 = styled(Button)`
  animation: ${shadowPulse2} 1s infinite;
`;
const StyledButton3 = styled(Button)`
  animation: ${shadowPulse3} 1s infinite;
`;
const StyledButton4 = styled(Button)`
  animation: ${shadowPulse4} 1s infinite;
`;
const StyledButton5 = styled(Button)`
  animation: ${shadowPulse5} 1s infinite;
`;
const FormItem = Form.Item;
const { Option } = Select;
const ProjectCards = styled(Card)`
  background-color: transparent;
  margin-bottom: 3em;
  top: -0em;
  margin: auto;
  text-align: center;
  height: 18em;
  width: 22em;
  border-radius: 1em;
  box-shadow: none;
  border: 0px solid #2482db;
  @media (max-width: 1000px) {
  }
  @media (max-width: 770px) {
  }
`;
const StyledInputNumber = styled(InputNumber)`
  border-top: 0px solid #d9d9d9;
  border-left: 0px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-bottom: 1px solid #158577;

  color: black;
  background-color: transparent;
  border: 0;

  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
`;
const StyledSelect = styled(Select)`
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    font-weight: 9000;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -6px;
    color: white;
    font-size: 17px;
    line-height: 1;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    margin-left: -8px;
    height: 20px;

    overflow: hidden;
    color: rgba(255, 255, 255, 0.753);
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
  }
  .ant-select-selection {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    padding-left: 1.8em !important;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 55px !important;
    padding: 4px 11px;
    color: white;
    box-shadow: none;
    line-height: 1.5;
    background-color: transparent;
    background-image: none;
    border: 2px solid white;
    border-radius: 50px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  :placeholder {
    color: red !important;
  }
`;
const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-left: 1.8em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 55px !important;
  padding: 4px 11px;
  color: white;
  box-shadow: none;
  line-height: 1.5;
  background-color: transparent;
  background-image: none;
  border: 2px solid white;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  :placeholder {
    color: red !important;
  }
`;

$(document).ready(function () {
  var html = "";
  for (var i = 1; i <= 50; i++) {
    html +=
      '<div class="shape-container--' +
      i +
      ' shape-animation"><div class="random-shape"></div></div>';
  }

  document.querySelector(".shape").innerHTML += html;

  var $allShapes = $("[class*='shape-container--']");
});

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
function hasErrors1(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

function CardAn() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <div
      class="container"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div style={{ transform: props.xy.interpolate(trans1) }}>
        <img
          className="home-image"
          src={HomeImg}
          style={{
            zIndex: 9,
          }}
        ></img>

        <img
          className="home-image-mobile"
          src={HomeImgMobile}
          style={{
            zIndex: 9,
          }}
        ></img>
      </animated.div>
    </div>
  );
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      inputValue: 1,
      FormChange: false,
      visible: false,
      open: false,
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    document.title = "Home - Vinton Research Solutions";
    document.documentElement.scrollTop = 0;
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.onSubmit(values);
      }
    });
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.reload(false);
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onSubmit = (values) => {
    const MSG = {
      to: ["peterv@vinton.co.za"],
      from: "info@venturepoint.co.za",
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="white">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/6PdhcHK/v-logo-white.png" width="300" height="100" style="display: block; width:195px; max-width: 195px; min-width: 195px;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #E96B4A; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:#E96B4A;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: #E96B4A;line-height:135%;">You have received a new contact request from <br /> the Vinton Research Solutions website</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>

        <!-- MODULE ROW // -->
        
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                               <strong> Company </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${values.company}<br />
                               <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email}<br />
                               <strong> Package </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.package}<br />
                               <strong> Message </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.more}<br />
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="white" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2020 Vinton Research Solutions</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name: "",
        company: "",
        email: "",
        package: "",
        more: "",
      });
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { inputValue } = this.state;
    return (
      <React.Fragment>
        {" "}
        <ExitIntent />
        <Icon
          className="burger-menu"
          onClick={this.showDrawer}
          style={{
            color: "#E96B4A",
            fontSize: "28px",
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 3,
            border: "none",
            position: "absolute",
            boxShadow: "none",
          }}
          type="menu"
        ></Icon>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <br />
          <a href="./#home" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-packages"
          >
            Survey Packages
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-examples"
          >
            Survey Example
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#blog"
          >
            Blogs
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#contact-us"
          >
            Get Started
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./faq"
          >
            FAQ
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./videos"
          >
            Videos
          </a>
        </Drawer>{" "}
        <img
          id="home"
          className="home-image"
          src={HomeImg}
          style={{
            zIndex: 9,
          }}
        ></img>
        <Header />
        <ChatBot />
        <div
          className="home-div"
          style={{
            padding: "30px",
            minHeight: "55em",
            maxHeight: "100em",
            width: "100vw",
            height: "88vh",
          }}
        >
          <div class="container">
            <div class="inner-container">
              <div class="shape"></div>
            </div>
          </div>
          <Row
            className="home-row"
            style={{
              maxWidth: "115em",
              bottom: "-10vw",
              margin: "auto",
            }}
          >
            <Col sm={24} md={12}>
              <Slide delay={200} duration={1800} left>
                <h1
                  className="home-header"
                  style={{
                    fontSize: "70px",
                    fontWeight: 700,
                    lineHeight: "1em",
                    marginTop: "0%",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "nunito",
                    color: "#000000",
                  }}
                >
                  {" "}
                  Engagement Equals
                  <br />{" "}
                  <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                    Results{" "}
                    <img
                      src={HorizontalLine}
                      style={{
                        width: "2.2em",
                        position: "absolute",
                        marginTop: "1em",
                        marginLeft: "-2em",
                      }}
                    ></img>{" "}
                  </span>
                </h1>
              </Slide>
              <Slide delay={400} duration={1800} left>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "3em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito-regular",
                  }}
                >
                  Vinton Research Solutions are disrupting the way in
                  <br />
                  which run-of-the-mill surveys are run.
                </p>{" "}
              </Slide>
              <Slide delay={1300} duration={2000} left>
                <a href="#about-us">
                  <Button
                    className="home-button-1"
                    style={{
                      height: "2.8em",
                      width: "13em",
                      fontSize: "20px",
                      fontWeight: 500,
                      borderRadius: "3em",
                      color: "white",
                      border: "none",
                      marginTop: "1em",
                      backgroundColor: "#E96B4A",
                      fontFamily: "nunito-regular",
                      textShadow: "none",
                      boxShadow: "none",
                    }}
                  >
                    About Us
                  </Button>
                </a>
              </Slide>
            </Col>
            <Col sm={24} md={12} style={{ zIndex: 1 }}>
              <img
                className="home-image-mobile-home"
                src={HomeImgMobile}
                style={{
                  zIndex: 9,
                }}
              ></img>
            </Col>{" "}
          </Row>
        </div>
        <Row
          id="about-us"
          style={{
            textAlign: "center",
            maxWidth: "120em",
            margin: "auto",
          }}
        >
          <Col>
            <h1
              className="center-header"
              style={{
                marginLeft: "0.5em",
                fontSize: "55px",
                fontWeight: 700,
                lineHeight: "1em",
                marginTop: "0%",
                color: "rgb(248, 248, 248)",
                fontFamily: "nunito",
                color: "#000000",
              }}
            >
              Create An Awesome
              <br />
              <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                Respondent Experience{" "}
                <img
                  src={HorizontalLine}
                  style={{
                    width: "2.8em",
                    position: "absolute",
                    marginTop: "1em",
                    marginLeft: "-2.5em",
                  }}
                ></img>{" "}
              </span>
            </h1>
            <p
              className="center-paragraph"
              style={{
                marginLeft: "1.5em",
                color: "#000000",
                fontWeight: 500,
                fontSize: "21px",

                lineHeight: "1.2em",
                marginTop: "-0.5em",
                fontFamily: "nunito-regular",
              }}
            >
              Dull, boring text-only surveys are a thing of the past with our
              groundbreaking
              <br />
              survey technology that ensures an awesome survey respondent
              experience.
            </p>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "100em",
            margin: "auto",
            marginTop: "4em",
          }}
        >
          {" "}
          <Col md={8} sm={24} style={{ textAlign: "right" }}>
            <Slide duration={1200} left>
              <h1
                className="center-header"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                New Survey Tech
              </h1>{" "}
            </Slide>
            <Slide duration={1000} left>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                At Vinton Research Solutions our creative and talented team are
                disrupting run-of-the-mill outdated surveys. Its time to turn
                around dull and boring surveys so that your survey respondent
                experiences an interactive and entertaining survey journey which
                results in an awesome respondent experience!
              </p>
            </Slide>
          </Col>
          <Col md={8} sm={24} style={{ textAlign: "center" }}>
            <Slide duration={1000} bottom>
              <img
                className="pragraph-icons"
                src={VideoIcon}
                style={{ marginTop: "1em" }}
              ></img>
            </Slide>
          </Col>
          <Col md={8} sm={24} style={{ textAlign: "left" }}>
            <Slide duration={1200} right>
              <h1
                className="center-header-audio"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                Audio & Video Surveys
              </h1>
            </Slide>
            <Slide duration={1000} right>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                Did you know that audio & visual content receives a much higher
                interaction rate than static content? The production team at
                Vinton Research Solutions works closely with our survey
                development team to create your interactive audio or video
                survey. We produce custom-made company videos to include in your
                survey introduction and a short video for each question.
              </p>
            </Slide>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "100em",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          <Col md={8} sm={24} style={{ textAlign: "right" }}>
            <Slide duration={1200} left>
              <h1
                className="center-header"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                Automated Email
                <br /> Invites & Alerts
              </h1>{" "}
            </Slide>
            <Slide duration={1000} left>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                Part of any survey campaign is grabbing your audience's
                attention. Vinton Research Solutions designs, writes & creates a
                catchy survey invitation. Our technology gives you the choice to
                distribute a survey mailshot, or add your branding and embed the
                survey link on your website. You and your survey respondent also
                gets an automated mail once they have completed the survey.
              </p>
            </Slide>
          </Col>
          <Col
            className="pragraph-icons"
            md={8}
            sm={24}
            style={{ textAlign: "center" }}
          >
            <Slide duration={1000} bottom>
              <img src={MessageIcon} style={{ marginTop: "3em" }}></img>
            </Slide>
          </Col>
          <Col md={8} sm={24} style={{ textAlign: "left" }}>
            <Slide duration={1200} right>
              <h1
                className="center-header-audio"
                style={{
                  fontSize: "35px",
                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                Your Branding
              </h1>
            </Slide>
            <Slide duration={1000} right>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                With thousands of brands out there, conducting a survey with us
                ensures that your brand is seen as an organisation that values
                their audience opinions. Your company's logo, website links,
                colours are all developed to fully brand your survey. We also
                supply a link to fully embed your survey on your company's
                website, which is an awesome brand-building feature.
              </p>
            </Slide>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "100em",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          <Col md={8} sm={24} style={{ textAlign: "right" }}>
            <Slide duration={1200} left>
              <h1
                className="center-header"
                style={{
                  fontSize: "35px",

                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                Mobile, Tablet & <br />
                Desktop Access
              </h1>
            </Slide>
            <Slide duration={1000} left>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",

                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                With more and more people on the go Vinton Research Solutions
                have made it easy for respondents to complete your survey from
                any device. Our cutting edge technology enables respondents to
                get interactive and visual while completing the survey via their
                mobile device.
              </p>
            </Slide>
          </Col>
          <Col
            className="pragraph-icons"
            md={8}
            sm={24}
            style={{ textAlign: "center" }}
          >
            {" "}
            <Slide duration={1000} bottom>
              <img src={TabsIcon} style={{ marginTop: "1em" }}></img>
            </Slide>
          </Col>
          <Col md={8} sm={24} style={{ textAlign: "left" }}>
            <Slide duration={1200} right>
              <h1
                className="center-header-audio"
                style={{
                  fontSize: "35px",

                  lineHeight: "1em",
                  marginTop: "0%",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#000000",
                }}
              >
                Survey Reports
              </h1>
            </Slide>
            <Slide duration={1000} right>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 100,
                  fontSize: "18px",

                  lineHeight: "1.2em",
                  marginTop: "1em",
                }}
              >
                Using the latest technology Vinton Research Solutions send
                survey results report to the respondents. Each mail is
                personalised, which allows your respondents to explore the
                survey results at a glance. Survey results can also be
                downloaded and a PDF or an Excel document.
              </p>
            </Slide>
          </Col>
        </Row>
        <Row
          id="survey-packages"
          style={{
            maxWidth: "120em",
            textAlign: "center",
            margin: "auto",
            marginTop: "6em",
          }}
        >
          <h1
            style={{
              fontSize: "55px",
              fontWeight: 700,
              lineHeight: "1em",
              color: "rgb(248, 248, 248)",
              fontFamily: "nunito",
              color: "#000000",
            }}
          >
            Survey
            <span style={{ color: "#E96B4A", fontWeight: 500 }}>
              {" "}
              Packages{" "}
              <img
                src={HorizontalLine}
                style={{
                  width: "2.8em",
                  position: "absolute",
                  marginTop: "1.15em",
                  marginLeft: "-2.5em",
                }}
              ></img>{" "}
            </span>
          </h1>
          <p
            style={{
              color: "#000000",
              fontWeight: 500,
              fontSize: "21px",
              lineHeight: "1.2em",
              marginTop: "-0.5em",
              fontFamily: "nunito-regular",
            }}
          >
            Click on our unique survey packages to see how they work
          </p>
        </Row>
        <Row
          style={{
            maxWidth: "100em",
            textAlign: "center",
            margin: "auto",
            marginTop: "2em",
          }}
        >
          <Col md={8} sm={24} style={{}}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "1em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card1 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                <h1
                  style={{
                    fontSize: "45px",

                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Snap Quiz
                </h1>
                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "1.5em",
                    fontFamily: "nunito",
                  }}
                >
                  Need data in a hurry? <br />
                  <br />
                  We'll whip up a fun, interactive Snap Quiz so you get answers
                  quickly.
                </p>{" "}
                <Row>
                  <Col span={12}>
                    <a href="https://su.vc/examplesur" target="_blank">
                      <StyledButton
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "4.3em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/snap-quiz">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "4.3em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>{" "}
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
          <Col md={8} sm={24}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "1em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card3 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                {" "}
                <h1
                  style={{
                    fontSize: "45px",

                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Mini Survey
                </h1>
                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "1.5em",
                    fontFamily: "nunito",
                  }}
                >
                  Time is money, right?
                  <br />
                  <br />
                  If you need information from your target audience in a hurry.
                </p>
                <Row>
                  <Col span={12}>
                    <a
                      href="https://s.surveyanyplace.com/s/minisurvey"
                      target="_blank"
                    >
                      <StyledButton1
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "4em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton1>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/mini-survey">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "4em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
          <Col md={8} sm={24}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "1em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card4 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                {" "}
                <h1
                  style={{
                    fontSize: "45px",

                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Classic Survey
                </h1>
                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "1.5em",
                    fontFamily: "nunito",
                  }}
                >
                  In-depth data collection is vital. <br />
                  <br />
                  Our Classic Survey enables your brand to get crucial detailed
                  answers and dramatically increase brand awareness.
                </p>{" "}
                <Row>
                  <Col span={12}>
                    <a href="https://su.vc/oonpmzef" target="_blank">
                      <StyledButton2
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "1.8em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton2>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/classic-survey">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "1.8em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>{" "}
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
        </Row>
        <Row
          style={{
            maxWidth: "100em",
            textAlign: "center",
            margin: "auto",
            marginBottom: "6em",
          }}
        >
          <Col md={8} sm={24} style={{}}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "1em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card5 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                {" "}
                <h1
                  style={{
                    fontSize: "45px",

                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Audio Survey
                </h1>
                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "1.5em",
                    fontFamily: "nunito",
                  }}
                >
                  Let your brand ‘speak’ for itself – literally.
                  <br />
                  <br />
                  Our unique custom-produced Audio Survey is a fun way to
                  interact with your audience who often is experiencing text
                  fatigue.
                </p>
                <Row>
                  <Col span={12}>
                    <a
                      href="https://s.surveyanyplace.com/s/vsaudiosurvey"
                      target="_blank"
                    >
                      <StyledButton3
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "1.2em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton3>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/audio-survey">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "1.2em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
          <Col md={8} sm={24}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "1em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card6 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                {" "}
                <h1
                  style={{
                    fontSize: "45px",

                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Video Survey
                </h1>
                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",
                    lineHeight: "1.2em",
                    marginTop: "1.5em",
                    fontFamily: "nunito",
                  }}
                >
                  Show Off Your Brand - Get Results
                  <br />
                  <br />
                  Did you know that video marketing enjoys 80% more engagement
                  with their audience? It's time to get visual with your brand.
                </p>
                <Row>
                  <Col span={12}>
                    <a href="https://su.vc/vsvideosurvey" target="_blank">
                      <StyledButton4
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "2.2em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton4>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/video-survey">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "2.2em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
          <Col md={8} sm={24}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards-full-assessment"
                style={{
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "2em",
                  marginBottom: "2em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card7 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "28em",
                }}
              >
                <h1
                  style={{
                    fontSize: "45px",
                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Full Assessment
                </h1>

                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "0em",
                    fontFamily: "nunito",
                  }}
                >
                  Get The Big Picture <br />
                  <br />
                  Often times, such as, a full company or employee assessment is
                  needed to get a comprehensive assessment report. Our
                  Assessment Survey goes into great detail with your assessment
                  objective.
                </p>
                <Row>
                  <Col span={12}>
                    <a href="https://su.vc/zskjvyri" target="_blank">
                      <StyledButton5
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",

                          marginTop: "0em",
                          backgroundColor: "rgba(255, 255, 255, 0.521)",

                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Try Our Demo
                      </StyledButton5>
                    </a>
                  </Col>
                  <Col span={12}>
                    <Link to="/full-assessment">
                      <Button
                        className="home-button"
                        style={{
                          height: "2.5em",
                          width: "7.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "white",
                          border: "none",
                          marginTop: "0em",
                          backgroundColor: "#E96B4A",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        More info
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
        </Row>
        <Row>
          <Col md={24} sm={24}>
            <Slide delay={300} duration={1000} up>
              <Card
                className="sell-cards-full-assessment"
                style={{
                  textAlign: "center",
                  borderBottom: "4px solid #E96B4A",
                  margin: "auto",
                  marginTop: "-6em",
                  borderRadius: "2em",
                  backgroundImage: "url(" + Card8 + ")",
                  backgroundSize: "cover",
                  boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
                  maxHeight: "30em",
                  maxWidth: "70em",
                }}
              >
                <h1
                  style={{
                    fontSize: "45px",
                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "rgb(248, 248, 248)",
                    fontFamily: "Roboto",
                    color: "white",
                  }}
                >
                  Decision Maker’s 2021 Survey
                </h1>

                <p
                  style={{
                    color: "white",
                    fontWeight: 300,
                    fontSize: "20px",

                    lineHeight: "1.2em",
                    marginTop: "-1em",
                    fontFamily: "nunito",
                  }}
                >
                  <br />
                  In the wake of the Covid-19 pandemic and keeping in mind the
                  dire need for all South African businesses to make significant
                  decisions, Vinton Research Solutions is conducting a Decision
                  Maker’s survey that delves into the state of the current
                  business landscape in South Africa.
                </p>
                <Row>
                  <Col span={24}>
                    <Link to="/decision-maker-survey">
                      <StyledButton5
                        className="home-button"
                        style={{
                          height: "2.5em",
                          fontSize: "20px",
                          fontWeight: 500,
                          borderRadius: "3em",
                          color: "#E96B4A",
                          border: "1px solid #E96B4A",
                          marginTop: "0em",
                          backgroundColor: "rgba(255, 255, 255, 0.801)",
                          fontFamily: "nunito-regular",
                          textShadow: "none",
                          boxShadow: "none",
                        }}
                      >
                        Start Our Survey
                      </StyledButton5>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Slide>
          </Col>
        </Row>
        {/* <WrappedHorizontalSignUpForm /> */}
        {/* <Row
          style={{
            maxWidth: "120em",
            textAlign: "center",
            margin: "auto",
            marginTop: "15em",
          }}
        >
          <h1
            id="survey-example"
            style={{
              fontSize: "55px",
              fontWeight: 700,
              lineHeight: "1em",
              color: "rgb(248, 248, 248)",
              fontFamily: "nunito",
              color: "#000000",
            }}
          >
            Survey
            <span style={{ color: "#E96B4A", fontWeight: 500 }}>
              {" "}
              Example{" "}
              <img
                src={HorizontalLine}
                style={{
                  width: "2.8em",
                  position: "absolute",
                  marginTop: "1.15em",
                  marginLeft: "-2.5em",
                }}
              ></img>{" "}
            </span>
          </h1>
          <p
            style={{
              color: "#000000",
              fontWeight: 500,
              fontSize: "21px",
              lineHeight: "1.2em",
              marginTop: "-0.5em",
              fontFamily: "nunito-regular",
            }}
          >
            Get visual and engage your audience like never before
          </p>
        </Row>
        <div
          style={{
            width: "75%",
            margin: "auto",
            borderTop: "5px solid #E96B4A",
            borderBottom: "5px solid #E96B4A",
            borderRadius: "1em",
          }}
          class="embed-survey"
          data-url="examplesur"
        ></div>
        <Row
          className="survey-section"
          style={{
            margin: "auto",
            minWidth: "103em",
            maxWidth: "120em",
          }}
        >
          <Col
            md={12}
            sm={24}
            className="survey-img"
            style={{ textAlign: "left" }}
          >
            <img src={MobileSide} style={{ marginLeft: "-10em" }}></img>
          </Col>
          <Col
            className="survey-paragraph"
            md={12}
            sm={24}
            style={{ paddingTop: "20em" }}
          >
            <h1
              style={{
                fontSize: "55px",
                fontWeight: 700,
                lineHeight: "1em",
                color: "rgb(248, 248, 248)",
                fontFamily: "nunito",
                color: "#000000",
              }}
            >
              Mobile{" "}
              <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                {" "}
                Accessible{" "}
                <img
                  src={HorizontalLine}
                  style={{
                    width: "2.8em",
                    position: "absolute",
                    marginTop: "1em",
                    marginLeft: "-2.5em",
                  }}
                ></img>{" "}
              </span>
            </h1>
            <p
              style={{
                color: "black",
                fontWeight: 700,
                fontSize: "21px",

                lineHeight: "1.2em",
                marginTop: "-1em",
                fontFamily: "nunito",
              }}
            >
              Never miss a respondent.
            </p>{" "}
            <br /> <br />
            <p
              style={{
                color: "#000000",
                fontWeight: 100,
                fontSize: "21px",
                lineHeight: "1.2em",
                marginTop: "-1em",
              }}
            >
              Vinton Research Solutions enables your survey respondent to
              <br /> access your survey across all platforms and devices,
              including your mobile phone.
              <br />
              <br /> Your survey is available anytime, anywhere. <br />​ <br />
              <span style={{ fontWeight: 400, color: "#E96B4A" }}>
                {" "}
                • Mobile support <br />
                • Embeddable code
                <br />
                • Use on any website
                <br />• Easily accessible surveys
              </span>
            </p>
          </Col>
        </Row> */}
        <Row
          id="blog"
          style={{
            maxWidth: "120em",
            textAlign: "center",
            margin: "auto",
            marginTop: "8em",
          }}
        >
          <h1
            style={{
              fontSize: "55px",
              fontWeight: 700,
              lineHeight: "1em",
              color: "rgb(248, 248, 248)",
              fontFamily: "nunito",
              color: "#E96B4A",
            }}
          >
            Blogs
            <img
              src={HorizontalLine}
              style={{
                width: "2.8em",
                position: "absolute",
                marginTop: "1.15em",
                marginLeft: "-2.3em",
              }}
            ></img>
          </h1>
          <Row
            className="blogs-row"
            style={{ height: "30em", overflow: "hidden" }}
          >
            <Col className="blog-cards" md={24} sm={24}>

              <AllPosts /> <AllPosts />

            </Col>
          </Row>{" "}
          <Slide delay={500} duration={1500} bottom>
            <Link to="/blogs">
              <Button
                className="home-button"
                style={{
                  height: "2.5em",
                  width: "10em",
                  fontSize: "20px",
                  fontWeight: 500,
                  borderRadius: "3em",
                  color: "white",
                  border: "none",
                  marginTop: "1.3em",
                  backgroundColor: "#E96B4A",
                  fontFamily: "nunito-regular",
                  textShadow: "none",
                  boxShadow: "none",
                }}
              >
                More Blogs
              </Button>{" "}
            </Link>
          </Slide>
          {/*} <Col className="blog-cards" md={8} sm={24} style={{}}>
            <div
              style={{
                margin: "auto",
                borderRadius: "2em",
                width: "25em",
              }}
            >
              <h1
                style={{
                  fontSize: "30px",
                  zIndex: 9,
                  lineHeight: "1em",
                  marginTop: "0.5em",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#002430",
                }}
              >
                New Survey Tech
              </h1>
              <p
                style={{
                  color: "#002430",
                  fontWeight: 300,
                  fontSize: "13px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                  fontFamily: "nunito",
                }}
              >
                Leon Schmidt
                <br /> 30 Oct. 2020
              </p>
              <p
                style={{
                  color: "#002430",
                  fontWeight: 300,
                  fontSize: "19px",
                  lineHeight: "1.6em",
                  marginTop: "1em",
                }}
              >
                At Vinton Research Solutions our creative and talented team are
                disrupting run-of-the-mill outdated surveys. It’s time to turn
                around dull and boring surveys so that your survey respondent
                experiences an interactive and entertaining survey journey which
                results in an awesome respondent experience!
              </p>
              <Button
                className="home-button"
                style={{
                  height: "2.5em",
                  width: "9em",
                  fontSize: "22px",
                  fontWeight: 500,
                  borderRadius: "3em",
                  color: "#E96B4A",
                  border: "none",
                  marginTop: "0.5em",
                  border: "1px solid #E96B4A",
                  backgroundColor: "rgba(255, 255, 255, 0.681",
                  fontFamily: "nunito-regular",
                  textShadow: "none",
                  boxShadow: "none",
                }}
              >
                Read more
              </Button>
              <img
                src={Card2}
                style={{
                  zIndex: -1,
                  marginTop: "-15em",
                  position: "absolute",
                  marginTop: "-10em",
                  marginLeft: "-18.5em",
                }}
              />
            </div>
          </Col>
          <Col className="blog-cards" md={8} sm={24} style={{}}>
            <div
              style={{
                margin: "auto",
                borderRadius: "2em",
                width: "25em",
              }}
            >
              {" "}
              <h1
                style={{
                  fontSize: "30px",
                  zIndex: 9,
                  lineHeight: "1em",
                  marginTop: "0.5em",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito-regular",
                  color: "#002430",
                }}
              >
                New Survey Tech
              </h1>
              <p
                style={{
                  color: "#002430",
                  fontWeight: 300,
                  fontSize: "13px",
                  lineHeight: "1.2em",
                  marginTop: "1em",
                  fontFamily: "nunito",
                }}
              >
                Leon Schmidt
                <br /> 30 Oct. 2020
              </p>
              <p
                style={{
                  color: "#002430",
                  fontWeight: 300,
                  fontSize: "19px",

                  lineHeight: "1.6em",
                  marginTop: "1em",
                }}
              >
                At Vinton Research Solutions our creative and talented team are
                disrupting run-of-the-mill outdated surveys. It’s time to turn
                around dull and boring surveys so that your survey respondent
                experiences an interactive and entertaining survey journey which
                results in an awesome respondent experience!
              </p>
              <Button
                className="home-button"
                style={{
                  height: "2.5em",
                  width: "9em",
                  fontSize: "22px",
                  fontWeight: 500,
                  borderRadius: "3em",
                  color: "#E96B4A",
                  border: "none",
                  marginTop: "0.5em",
                  border: "1px solid #E96B4A",
                  backgroundColor: "rgba(255, 255, 255, 0.681",
                  fontFamily: "nunito-regular",
                  textShadow: "none",
                  boxShadow: "none",
                }}
              >
                Read more
              </Button>
              <img
                src={Card2}
                style={{
                  zIndex: -1,
                  marginTop: "-15em",
                  position: "absolute",
                  marginTop: "-10em",
                  marginLeft: "-18.5em",
                }}
              />
            </div>
              </Col> */}
        </Row>
        <Row
          gutter={24}
          style={{
            margin: "auto",
          }}
        >
          <Row gutter={24} style={{ maxWidth: "90em", margin: "auto" }}>
            <Col
              className="contact-col"
              span={18}
              style={{ marginTop: "15em" }}
            >
              <Card
                className="message-card"
                style={{
                  margin: "auto",
                  borderRadius: "3.5em",
                  width: "100%",
                  padding: "0px",
                  maxWidth: "60em",
                  backgroundColor: "transparent",
                  marginBottom: "-10.5em",
                  paddingTop: "0em",
                  boxShadow: "none",
                  zIndex: 9,
                }}
                bordered={false}
              >
                {" "}
                <h1
                  className="contact-div"
                  style={{
                    fontSize: "55px",
                    lineHeight: "1em",

                    marginLeft: "1em",
                    color: "white",
                  }}
                >
                  {" "}
                  <h1
                    className="center-header-contact"
                    style={{
                      fontSize: "55px",
                      fontFamily: "nunito-regular",
                      lineHeight: "1em",
                      color: "white",
                    }}
                  >
                    Get in touch{" "}
                    <img
                      src={HorizontalLine}
                      style={{
                        filter: "brightness(10.5)",
                        width: "2.8em",
                        position: "absolute",
                        marginTop: "1.15em",
                        marginLeft: "-1.8em",
                      }}
                    ></img>{" "}
                  </h1>
                  <p
                    className="center-paragraph-why1"
                    style={{
                      fontSize: "19px",
                      fontFamily: "nunito-regular",
                      lineHeight: "1em",
                      marginTop: "0.8em",
                      padding: "0.5em",
                      color: "white",
                    }}
                  >
                    It’s Time to GET actionable RESULTS with Vinton Research
                    Solutions
                  </p>
                </h1>
                <Form onSubmit={this.handleSubmit}>
                  <Row gutter={24} style={{ margin: "auto" }}>
                    <Col sm={24} md={12}>
                      <FormItem
                        className="form-item-style"
                        style={{
                          marginLeft: "3em",
                        }}
                      >
                        {getFieldDecorator("name", {
                          rules: [
                            { required: true, message: "Full name required" },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",

                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Name"
                          />
                        )}{" "}
                      </FormItem>
                    </Col>{" "}
                    <Col sm={24} md={12}>
                      <FormItem
                        className="form-item-style"
                        style={{
                          marginTop: "0em",

                          marginRight: "3em",
                        }}
                      >
                        {getFieldDecorator("company", {
                          rules: [
                            {
                              message: "Company required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",

                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Company (Optional)"
                          />
                        )}{" "}
                      </FormItem>
                    </Col>
                  </Row>{" "}
                  <Row
                    gutter={24}
                    style={{ margin: "auto", marginTop: "0em" }}
                    className="mail-item-margin-style"
                  >
                    <Col sm={24} md={12}>
                      <FormItem
                        style={{ marginLeft: "3em" }}
                        className="form-item-style"
                      >
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: "Email address required",
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Email address"
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                    <Col sm={24} md={12}>
                      <FormItem
                        className="form-item-style-number"
                        style={{ marginRight: "3em" }}
                      >
                        {getFieldDecorator("package", {
                          rules: [{}, ,],
                        })(
                          <StyledSelect
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Survey Package"
                          >
                            <Option value="Snap Quiz">Snap Quiz</Option>
                            <Option value="Mini Survey">Mini Survey</Option>
                            <Option value="Classic Survey">
                              Classic Survey
                            </Option>
                            <Option value="Audio Survey">Audio Survey</Option>
                            <Option value="Video Survey">Video Survey</Option>
                            <Option value="Assessment">Assessment</Option>
                          </StyledSelect>
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                  </Row>
                  <Row
                    gutter={24}
                    style={{ margin: "auto", marginTop: "0em" }}
                    className="mail-item-margin-style"
                  >
                    <Col sm={24} md={24}>
                      <FormItem
                        style={{ marginLeft: "3em", marginRight: "3em" }}
                        className="form-item-style"
                      >
                        {getFieldDecorator("more", {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <StyledInput
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                            }}
                            id="standard-basic"
                            placeholder="Tell Us More "
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                  </Row>
                  <Row
                    className="send-message-button"
                    style={{
                      margin: "auto",
                      textAlign: "right",
                      marginRight: "3.8em",
                    }}
                  >
                    <button
                      disabled={hasErrors(getFieldsError())}
                      htmlType="submit"
                      style={{
                        border: "none",
                        fontSize: "20px",
                        fontWeight: 700,
                        border: "0px solid #00D326",
                        marginTop: "1em",
                        background: "white",
                        color: "rgb(233, 107, 74)",
                        borderRadius: "2em",
                        width: "35%",
                        minWidth: "10em",
                        height: "2.9em",
                        paddingTop: "0px",
                        fontFamily: "nunito-regular",
                        textShadow: "none",
                        boxShadow: "none",
                      }}
                      id="loginButton"
                      className="form-item-button-style"
                      type="primary"
                    >
                      Let’s Engage
                    </button>
                  </Row>
                </Form>{" "}
              </Card>{" "}
            </Col>
            <Col
              span={6}
              style={{
                zIndex: 99999,
                marginTop: "22em",
                marginLeft: "-5em",
                borderRadius: "3em",
              }}
            >
              <div>
                <iframe
                  style={{ borderRadius: "2em" }}
                  className="contact-hidden"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.026197311649!2d28.0504781!3d-26.130688799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950cc25e7dca0f%3A0x4fb5414f991ef2f7!2s25%20Rudd%20Rd%2C%20Illovo%2C%202196!5e0!3m2!1sen!2sza!4v1693227100899!5m2!1sen!2sza"
                  width="370"
                  height="350"
                  frameborder="0"
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </Col>
          </Row>{" "}
          <Row
            // gutter={24}
            style={{
              maxWidth: "90em",
              margin: "auto",
              borderTop: "1px solid white",
            }}
          >
            <Col
              className="contact-row"
              span={24}
              style={{
                // paddingLeft: "70px",
                textAlign: "center",
                zIndex: 9,
                whiteSpace: "nowrap",
                // display: "inline-flex",
                margin: "auto",
                marginTop: "6.5em",
                marginBottom: "-4em",
              }}
            >
              {" "}
              <Col sm={24} md={5} style={{ width: "12em", margin: "auto" }}>
                <Icon
                  style={{
                    textAlign: "center",
                    // position: "absolute",
                    fontFamily: "nunito-regular",
                    fontSize: "25px",
                    color: "white",
                    top: "-1.5em",
                    left: "3.8em",
                  }}
                  type="home"
                  theme="filled"
                />
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 100,
                    fontSize: "17px",
                    color: "white",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Johannesburg
                  </span>
                  <br />
                  25 Rudd Road
                  <br />
                  Illovo
                  <br />
                  2194
                  <br />
                </p>
              </Col>
              <Col sm={24} md={5} style={{ width: "16em", margin: "auto" }}>
                <Icon
                  style={{
                    textAlign: "center",
                    // position: "absolute",
                    fontFamily: "nunito-regular",
                    fontSize: "25px",
                    color: "white",
                    top: "-1.5em",
                    left: "3.8em",
                  }}
                  type="home"
                  theme="filled"
                />
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 100,
                    fontSize: "17px",
                    color: "white",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Cape Town
                  </span>
                  <br />
                  3rd Floor, 9 Long Street
                  <br />
                  Corner Riebeeck & Long Street
                  <br />
                  City Centre, Cape Town
                  <br />
                </p>
              </Col>
              <Col sm={24} md={4} style={{ width: "12em", margin: "auto" }}>
                <Icon
                  style={{
                    textAlign: "left",
                    // position: "absolute",
                    fontFamily: "nunito-regular",
                    fontSize: "25px",
                    color: "white",
                    top: "-1.7em",
                    left: "2.5em",
                  }}
                  type="phone"
                  theme="filled"
                />
                <p
                  style={{
                    textAlign: "center",

                    // float: "left",
                    fontWeight: 100,
                    fontSize: "17px",
                    color: "white",
                  }}
                >
                  +27 83 600 9941

                  <br />
                </p>
              </Col>{" "}
              <Col sm={24} md={3} style={{ width: "12em", margin: "auto" }}>
                <Icon
                  style={{
                    textAlign: "left",
                    // position: "absolute",
                    fontFamily: "nunito-regular",
                    fontSize: "25px",
                    color: "white",
                    top: "-1.5em",
                    left: "3.5em",
                  }}
                  type="mail"
                  theme="filled"
                />
                <br />
                <p
                  style={{
                    textAlign: "center",

                    // float: "left",
                    fontWeight: 100,
                    fontSize: "17px",
                    color: "white",
                  }}
                >
                  <a
                    className="hyperlink-hover"
                    style={{ color: "white" }}
                    href="mailto:peterv@vinton.co.za"
                  >
                    peterv@vinton.co.za
                  </a>

                </p>{" "}
              </Col>
              <Col sm={24} md={1} style={{ width: "8em", margin: "auto" }}>
                <a
                  href="https://www.facebook.com/vintonsurveys/?modal=admin_todo_tour"
                  target="_blank"
                  style={{}}
                >
                  <Icon
                    style={{
                      textAlign: "left",
                      // position: "absolute",
                      fontFamily: "nunito-regular",
                      fontSize: "25px",
                      color: "white",
                      top: "-1.5em",
                      left: "4em",
                    }}
                    type="facebook"
                    theme="filled"
                  />
                </a>
                <br />
                <p
                  style={{
                    textAlign: "center",
                    // width: "12em",
                    // float: "left",
                    fontWeight: 100,
                    fontSize: "17px",
                    color: "white",
                  }}
                >
                  Facebook
                </p>{" "}
              </Col>
              <Col sm={24} md={1} style={{ width: "8em", margin: "auto" }}>
                <a
                  href="https://www.linkedin.com/company/68868587/admin/"
                  style={{}}
                  target="_blank"
                >
                  <Icon
                    style={{
                      textAlign: "left",
                      // position: "absolute",
                      fontFamily: "nunito-regular",
                      fontSize: "25px",
                      color: "white",
                      top: "-1.5em",
                      left: "4em",
                    }}
                    type="linkedin"
                    theme="filled"
                  />
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      // width: "12em",
                      // float: "left",
                      fontWeight: 100,
                      fontSize: "17px",
                      color: "white",
                    }}
                  >
                    Linkedin
                  </p>{" "}
                </a>
              </Col>
              <Col sm={24} md={1} style={{ width: "8em", margin: "auto" }}>
                <a
                  href="https://www.instagram.com/vinton_surveys/?hl=en"
                  style={{}}
                  target="_blank"
                >
                  <Icon
                    style={{
                      textAlign: "left",
                      // position: "absolute",
                      fontFamily: "nunito-regular",
                      fontSize: "25px",
                      color: "white",
                      top: "-1.5em",
                      left: "4em",
                    }}
                    type="instagram"
                    theme="filled"
                  />
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      // width: "12em",
                      // float: "left",
                      fontWeight: 100,
                      fontSize: "17px",
                      color: "white",
                    }}
                  >
                    Instagram
                  </p>{" "}
                </a>
              </Col>
            </Col>
          </Row>
          <Row
            id="contact-us"
            style={{
              height: "45em",
              width: "100vw",
              position: "absolute",
              bottom: "-9em",
            }}
          >
            {" "}
            <img
              className="footer-image"
              src={FooterImg}
              style={{
                width: "100vw",
              }}
            ></img>
            <img
              className="footer-image-mobile"
              src={FooterImgMobile}
              style={{
                width: "100vw",
              }}
            ></img>
          </Row>
          <SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img src={logoWhite} alt="Logo" style={{}} />

            <h3
              style={{
                marginTop: "1em",
                color: "#E96B4A",
                textAlign: "center",
              }}
            >
              Thanks for engaging with us… remember Engagement Equals Results.
              <br />
              We’ll get in touch as soon as possible.
            </h3>
          </SuccessModal>
          <Row
            className="footer-burn"
            style={{
              position: "absolute",
              textAlign: "center",
              bottom: "-8em",
              right: 0,
              left: 0,
              color: "rgba(255, 255, 255, 0.753) ",
            }}
          >
            <p style={{ fontSize: "13px" }}>
              Copyright © 2023. Vinton Research Solutions. All rights reserved
            </p>
          </Row>
        </Row>
      </React.Fragment>
    );
  }
}
const WrappedHorizontalLoginForm = Form.create({ name: "horizontal_login" })(
  HomePage
);
export default WrappedHorizontalLoginForm;
