import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";
import HorizontalLine from "../static/images/horizontal-line.svg";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FAQImg from "../static/images/faq.jpg";
import FAQBack from "../static/images/faq-back.png";
import FAQImg1 from "../static/images/faq1.jpg";
import HomeImgMobile from "../static/images/device-mockup-snap-audio-survey-mobile.svg";
import RocketImg from "../static/images/rocket-image.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "./Header";
import ChatBot from "../Pages/chat-bot";
import styled from "styled-components";
import $ from "jquery";
const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      open: false,
    };
  }
  componentDidMount() {
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    $(document).ready(function () {
      var html = "";
      for (var i = 1; i <= 50; i++) {
        html +=
          '<div class="shape-container--' +
          i +
          ' shape-animation"><div class="random-shape"></div></div>';
      }

      document.querySelector(".shape").innerHTML += html;

      var $allShapes = $("[class*='shape-container--']");
    });
    document.title = "Audio Survey - Vinton Research Solutions";
    document.documentElement.scrollTop = 0;
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <ExitIntent />
        <Icon
          className="burger-menu"
          onClick={this.showDrawer}
          style={{
            color: "#E96B4A",
            fontSize: "28px",
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 3,
            border: "none",
            position: "absolute",
            boxShadow: "none",
          }}
          type="menu"
        ></Icon>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <br />
          <a href="./#home" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-packages"
          >
            Survey Packages
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#blog"
          >
            Blog
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#contact-us"
          >
            Get Started
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./faq"
          >
            FAQ
          </a>
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./videos"
          >
            Videos
          </a>
        </Drawer>{" "}
        <Header />
        <ChatBot />
        <div
          id="home"
          className="home-div"
          style={{
            padding: "30px",

            maxHeight: "100em",
            width: "100vw",
            height: "88vh",
          }}
        >
          {/* <div class="container1">
            <div class="inner-container1">
              
            </div>
        </div> */}
          <div class="container" style={{ display: "none" }}>
            <div class="inner-container">
              {" "}
              <div class="shape"></div>
            </div>
          </div>
          <Row
            className="home-row"
            style={{
              maxWidth: "80em",
              bottom: "-6vw",
              zIndex: 2,
              margin: "auto",
            }}
          >
            <Col sm={24} md={24} style={{}}>
              <Fade delay={0} duration={1500} left>
                <h1
                  className="home-header"
                  style={{
                    fontSize: "70px",
                    fontWeight: 700,
                    lineHeight: "0.9em",

                    color: "rgb(248, 248, 248)",
                    fontFamily: "nunito",
                    color: "#000000",
                  }}
                >
                  Frequently <br />
                  Asked
                  <br />
                  <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                    {" "}
                    Questions
                    <img
                      src={HorizontalLine}
                      style={{
                        width: "2.2em",
                        position: "absolute",
                        marginTop: "1em",
                        marginLeft: "-2em",
                      }}
                    ></img>{" "}
                  </span>
                </h1>
              </Fade>
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  1. How do you choose the right survey template for our survey
                  topic and objective ?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    All our survey templates are expertly designed and written
                    with a specific topic in mind to help you get the most
                    accurate and reliable survey results. Years of survey
                    experience has taught our survey design team what survey
                    template fits best for your research objective and topic.
                  </span>
                </p>{" "}
              </Fade>
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  2. Will you assist with the survey questionnaire development ?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Our research questionnaire specialists assist our clients to
                    develop the best possible survey questionnaire so that to
                    your business gets answers to the right questions.
                    <br />
                  </span>
                </p>
              </Fade>
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  3. Are we able to totally ‘brand’ our survey?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Yes. We offer our clients the value-added bonus of
                    completely branding your survey. Branding your survey
                    enables your business to not only create a successful market
                    research campaign but to also significantly boost your brand
                    awareness, ROI, and drive company growth.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  4. Will you assist with the survey questionnaire development?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Our research questionnaire specialists assist our clients to
                    develop the best possible survey questionnaire so that your
                    business gets answers to the right questions.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  5. Do you provide editorial survey material?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Our knowledgeable copywriters will interview your company
                    spokesperson or product specialist to gain a valuable inside
                    view of your survey objective. Once the comment is received
                    from your company, we write up insightful and
                    thought-provoking survey launch articles and once the survey
                    has closed, we follow the same process in expertly writing
                    your post-survey article.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  6. What is an Audio and Video survey?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Vinton Research Solutions is the first survey provider in SA
                    to introduce groundbreaking survey technology in the form of
                    our unique Audio and Video surveys. We are disrupting the
                    way ‘ outdated’ text-only surveys are done and can bring
                    your survey alive with exclusively produced ‘ audio ’ and or
                    ‘ video ’ based surveys. Everything is visual these days, so
                    why should surveys be any different?
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  7. How can running a survey with Vinton Research Solutions
                  benefit our marketing efforts?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    We understand the many benefits of running a survey and the
                    marketing of your brand is a massive part of our survey
                    experience. Our creative graphic design team work alongside
                    our copywriters to create top-class social media marketing
                    for your survey.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  8. Can a survey help us to discover what our competitors are
                  doing and identify best practices in your industry?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Without a doubt, surveys are a quick and effective way to
                    compare your company with the survey results to what your
                    competitors are doing and the industry averages. These types
                    of ‘ competitor benchmark research’ surveys help you know
                    where your business stands within your industry – indeed a
                    great place to be.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  9. Does employee engagement surveys build a better workplace?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    Employee engagement is vital to improving your work
                    environment. Employee feedback on issues such as evaluation,
                    skills assessments and training needs enables your company
                    to grow and thrive. Remember an engaged employee is a happy
                    team member.
                    <br />
                  </span>
                </p>{" "}
              </Fade>{" "}
              <Fade delay={300} duration={1500}>
                <p
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "23px",
                    paddingRight: "4em",
                    lineHeight: "1.5em",
                    marginTop: "0.8em",
                    fontFamily: "nunito",
                  }}
                >
                  10. How will we interpret the survey results data?
                  <br />
                  <span
                    className="center-paragraph"
                    style={{
                      color: "#456860",
                      fontWeight: 100,
                      fontSize: "20px",
                      paddingRight: "4em",
                      lineHeight: "0.1em",
                      marginTop: "0.8em",
                      fontFamily: "nunito-regular",
                    }}
                  >
                    We have an experienced survey team to help you understand
                    the data collected from your engagement through expert
                    reports in graph and chart form as well as written ‘key
                    findings ’ report on your survey data allowing you to take
                    action based on what your survey respondents say.
                    <br />
                  </span>
                </p>
              </Fade>
              <a href="./">
                <Button
                  className="home-button-3"
                  style={{
                    height: "2.8em",
                    width: "10em",
                    fontSize: "20px",
                    fontWeight: 500,
                    borderRadius: "3em",
                    color: "white",
                    border: "none",
                    marginTop: "0em",
                    backgroundColor: "#E96B4A",
                    fontFamily: "nunito-regular",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  Back to Home
                </Button>
              </a>
            </Col>{" "}
          </Row>{" "}
          <img
            className="home-image-faq"
            src={FAQImg}
            style={{
              opacity: 0.2,
              zIndex: 0,
            }}
          ></img>{" "}
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default FAQ;
