import React, { PureComponent } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import BotAvatar from "../static/images/chat-avatar.png";

// all available props
const theme = {
  background: "#f5f8fb",
  fontFamily: "nunito-regular",
  headerBgColor: "#E96B4A",
  headerFontColor: "white",
  headerFontSize: "15px",
  botBubbleColor: "#E96B4A",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const Chatbot = () => {
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        className="chatbot"
        botAvatar={BotAvatar}
        enableSmoothScroll="true"
        bubbleStyle={{ maxWidth: "80%", margin: "0.7em" }}
        avatarStyle={{ height: "50px", minWidth: "50px" }}
        contentStyle={{
          height: "460px",
        }}
        footerStyle={{ backgroundColor: "transparent" }}
        floatingStyle={{ backgroundColor: "rgb(233, 107, 74)" }}
        bubbleOptionStyle={{
          borderRadius: "10px",
          textAlign: "left",
          backgroundColor: "white",
          fontWeight: 500,
          color: "#E96B4A",
          border: "1px solid #E96B4A",
        }}
        headerTitle={
          <span style={{ color: "white", fontFamily: "nunito" }}>
            Let me help you
          </span>
        }
        hideUserAvatar="true"
        hideSubmitButton="true"
        floating="true"
        steps={[
          {
            id: "1",
            message:
              "Hello, I’m the VRS's service bot. Select your option below which I can assist you with.",
            trigger: "2",
          },

          {
            id: "3",
            message: "Is there anything else that I can help you with?",
            trigger: "4",
          },
          {
            id: "4",

            options: [
              {
                value: 1,
                label: "Yes",
                trigger: "1",
              },
              {
                value: 2,
                label: "No",
                trigger: "100",
              },
            ],
          },
          {
            id: "2",
            options: [
              {
                value: 1,
                label:
                  "How do you choose the right survey template for our survey topic and objective?",
                trigger: "50",
              },
              {
                value: 2,
                label:
                  "Will you assist with the survey questionnaire development?",
                trigger: "51",
              },
              {
                value: 3,
                label: "Are we able to totally ‘brand’ our survey?",
                trigger: "52",
              },
              {
                value: 4,
                label:
                  "Are Vinton Research Solutions' surveys mobile accessible?",
                trigger: "53",
              },
              {
                value: 5,
                label: "Do you provide editorial survey material?",
                trigger: "54",
              },
              {
                value: 6,
                label: "What is an Audio and Video survey?",
                trigger: "55",
              },
              {
                value: 7,
                label:
                  "How can running a survey with Vinton Research Solutions benefit our marketing efforts?",
                trigger: "56",
              },
              {
                value: 8,
                label:
                  "Can a survey help us to discover what our competitors are doing and identify best practices in your industry?",
                trigger: "57",
              },
              {
                value: 9,
                label:
                  "Does employee engagement surveys build a better workplace?",
                trigger: "58",
              },
              {
                value: 10,
                label: "How will we interpret the survey results data?",
                trigger: "59",
              },
            ],
          },
          {
            id: "50",
            message:
              "Our research questionnaire specialists assist our clients to develop the best possible survey questionnaire so that your business gets answers to the right questions.",
            trigger: "3",
          },
          {
            id: "51",
            message:
              "All our survey templates are expertly designed and written with a specific topic in mind to help you get the most accurate and reliable survey results.  Years of survey experience has taught our survey design team what survey template fits best for your research objective and topic",
            trigger: "3",
          },
          {
            id: "52",
            message:
              "Yes.  We offer our clients the value-added bonus of completely branding your survey.  Branding your survey enables your business t not only creating a successful market research campaign but will also significantly boost your brand awareness, ROI, and drive company growth. ",
            trigger: "3",
          },
          {
            id: "53",
            message:
              "Absolutely.  All our surveys, quizzes, and complete assessments are easily accessible for your respondents via their mobile phones or tablets.  With more and more people on the move, this is an added benefit to capture data while respondents are on the move.",
            trigger: "3",
          },
          {
            id: "54",
            message:
              "Our knowledgeable copywriters will interview your company spokesperson or product specialist to gain a valuable inside view of your survey objective.  Once the comment is received from your company, we write up insightful and thought-provoking survey launch articles and once the survey has closed, we follow the same process in expertly writing your post-survey article.",
            trigger: "3",
          },
          {
            id: "55",
            message:
              "Vinton Research Solutions is the first survey provider in SA to introduce groundbreaking survey technology in the form of our unique Audio and Video surveys.  We are disrupting the way ‘outdated’ text-only surveys are done and can bring your survey alive with exclusively produced ‘audio’ and or ‘video’ based surveys.   Everything is visual these days, so why should surveys be any different?",
            trigger: "3",
          },
          {
            id: "56",
            message:
              "We understand the many benefits of running a survey and the marketing of your brand is a massive part of our survey experience.   Our creative graphic design team work alongside our copywriters to create top-class social media marketing for your survey. ",
            trigger: "3",
          },
          {
            id: "57",
            message:
              "Without a doubt, surveys are a quick and effective way to compare your company with the survey results to what your competitors are doing and the industry averages.  These types of ‘competitor benchmark research’ surveys help you know where your business stands within your industry – indeed a great place to be.",
            trigger: "3",
          },
          {
            id: "58",
            message:
              "Employee engagement is vital to improving your work environment.  Employee feedback on issues such as evaluation, skills assessments and training needs enables your company to grow and thrive.  Remember an engaged employee is a happy team member.",
            trigger: "3",
          },
          {
            id: "59",
            message:
              "We have an experienced survey team to help you understand the data collected from your engagement through expert reports in graph and chart form as well as written ‘key findings’ report on your survey data allowing you to take action based on what your survey respondents say.",
            trigger: "3",
          },
          {
            id: "100",
            message: "Awesome , check in anytime for more help",
            end: true,
          },
        ]}
      />
    </ThemeProvider>
  );
};

export default Chatbot;
