import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";

import HorizontalLine from "../static/images/horizontal-line.svg";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import HomeImg from "../static/images/device-mockup-snap.svg";
import HomeImgMobile from "../static/images/device-mockup-mobile.svg";
import RocketImg from "../static/images/rocket-image.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "./Header";
import ChatBot from "../Pages/chat-bot";
import styled from "styled-components";
import $ from "jquery";
const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class SnapQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      open: false,
    };
  }
  componentDidMount() {
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    $(document).ready(function () {
      var html = "";
      for (var i = 1; i <= 50; i++) {
        html +=
          '<div class="shape-container--' +
          i +
          ' shape-animation"><div class="random-shape"></div></div>';
      }

      document.querySelector(".shape").innerHTML += html;

      var $allShapes = $("[class*='shape-container--']");
    });
    document.documentElement.scrollTop = 0;
    document.title = "Snap Quiz - Vinton Research Solutions";
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <ExitIntent />
        <Icon
          className="burger-menu"
          onClick={this.showDrawer}
          style={{
            color: "#E96B4A",
            fontSize: "28px",
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 3,
            border: "none",
            position: "absolute",
            boxShadow: "none",
          }}
          type="menu"
        ></Icon>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <br />
          <a href="./#home" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-packages"
          >
            Survey Packages
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#blog"
          >
            Blog
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#contact-us"
          >
            Contact Us
          </a>
        </Drawer>{" "}
        <a href="https://s.surveyanyplace.com/oonpmzef" target="_blank">
          <img
            className="home-image"
            src={HomeImg}
            style={{
              zIndex: 10,
            }}
          ></img>
        </a>
        <Header />
        <ChatBot />
        <div
          id="home"
          className="home-div"
          style={{
            padding: "30px",

            maxHeight: "100em",
            width: "100vw",
            height: "88vh",
          }}
        >
          {/* <div class="container1">
            <div class="inner-container1">
              
            </div>
        </div> */}
          <div class="container">
            <div class="inner-container">
              {" "}
              <div class="shape"></div>
            </div>
          </div>
          <Row
            className="home-row"
            style={{
              maxWidth: "120em",
              bottom: "-8vw",
              zIndex: 2,
              margin: "auto",
            }}
          >
            <Col sm={24} md={12} style={{}}>
              {" "}
              <h1
                className="home-header"
                style={{
                  display: "inline-flex",

                  fontSize: "70px",
                  fontWeight: 700,
                  lineHeight: "0.5em",

                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito",
                  color: "#000000",
                }}
              >
                <Fade duration={1100} delay={200} left cascade>
                  Snap
                  <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                    {" "}
                    &nbsp;Quiz{" "}
                    <img
                      src={HorizontalLine}
                      style={{
                        width: "2.2em",
                        position: "absolute",
                        marginTop: "0.85em",
                        marginLeft: "-2em",
                      }}
                    ></img>{" "}
                  </span>
                </Fade>
              </h1>
              <p
                className="center-paragraph"
                style={{
                  color: "#000000",
                  fontWeight: 500,
                  fontSize: "23px",
                  paddingRight: "7em",
                  lineHeight: "1.5em",
                  marginTop: "0.8em",
                  fontFamily: "nunito-regular",
                }}
              >
                Need data in a hurry? <br />
                <span
                  className="center-paragraph"
                  style={{
                    color: "#000000",
                    fontWeight: 100,
                    fontSize: "20px",
                    paddingRight: "4em",
                    lineHeight: "0.1em",
                    marginTop: "0.8em",
                    fontFamily:
                      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol",
                  }}
                >
                  We'll whip up a fun, interactive{" "}
                  <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                    Snap Quiz
                  </span>{" "}
                  so you get answers quickly.
                  <br />
                  <br /> We add a 'game-like' element by adding a quiz timer –
                  giving the respondents some excitement by having to beat the
                  ‘clock’. <br />
                  <br />
                  The respondents final score will pop up upon completion of
                  your{" "}
                  <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                    Snap Quiz{" "}
                  </span>
                  , giving them a sense of achievement and valuable feedback on
                  their knowledge.
                </span>
              </p>
              <a href="https://su.vc/examplesur" target="_blank">
                <Button
                  className="home-button-3"
                  style={{
                    height: "2.8em",
                    width: "8em",
                    fontSize: "20px",
                    fontWeight: 500,
                    borderRadius: "3em",
                    color: "white",
                    border: "none",
                    marginTop: "0em",
                    backgroundColor: "#E96B4A",
                    fontFamily: "nunito-regular",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  Try Our Demo
                </Button>
              </a>
              <a href="./#contact-us">
                <Button
                  className="home-button-4"
                  style={{
                    height: "2.8em",
                    width: "8em",
                    fontSize: "20px",
                    fontWeight: 500,
                    borderRadius: "3em",
                    color: "white",
                    border: "none",
                    margin: "0.5em",
                    backgroundColor: "#E96B4A",
                    fontFamily: "nunito-regular",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  Get Started
                </Button>
              </a>
            </Col>
            <Col sm={24} md={12} style={{ zIndex: 1 }}>
              <img
                className="home-image-mobile"
                src={HomeImgMobile}
                style={{
                  zIndex: 9,
                }}
              ></img>
            </Col>{" "}
          </Row>{" "}
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default SnapQuiz;
