import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Modal,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";

import HorizontalLine from "../static/images/horizontal-line.svg";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FAQImg from "../static/images/faq.jpg";
import FAQBack from "../static/images/faq-back.png";
import FAQImg1 from "../static/images/faq1.jpg";
import HomeImgMobile from "../static/images/device-mockup-snap-audio-survey-mobile.svg";
import RocketImg from "../static/images/rocket-image.png";
import Video1 from "../static/video/video-1.mp4";
import Video2 from "../static/video/video-2.mp4";
import Video3 from "../static/video/video-3.mp4";
import Video4 from "../static/video/video-4.mp4";
import Video5 from "../static/video/video-5.mp4";
import Video6 from "../static/video/video-6.mp4";
import Video7 from "../static/video/video-7.mp4";
import Video8 from "../static/video/video-8.mp4";
import Video9 from "../static/video/video-9.mp4";
import Video10 from "../static/video/video-10.mp4";
import Thumb1 from "../static/video/engagement-thumbnail.png";
import Thumb2 from "../static/video/break-thumbnail.png";
import Thumb3 from "../static/video/survey-thumbnail.png";
import Thumb4 from "../static/video/video-thumbnail.png";
import Thumb5 from "../static/video/time-thumbnail.png";
import Thumb6 from "../static/video/feedback-thumbnail.png";
import Thumb7 from "../static/video/2021-thumbnail.png";
import Thumb8 from "../static/video/data-thumbnail.png";
import Thumb9 from "../static/video/conduct-thumbnail.png";
import Thumb10 from "../static/video/time1-thumbnail.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "./Header";
import ChatBot from "../Pages/chat-bot";
import styled from "styled-components";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import $ from "jquery";
const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      visible1: false,
      open: false,
    };
  }
  componentDidMount() {
    $(document).ready(function () {
      var html = "";
      for (var i = 1; i <= 50; i++) {
        html +=
          '<div class="shape-container--' +
          i +
          ' shape-animation"><div class="random-shape"></div></div>';
      }

      document.querySelector(".shape").innerHTML += html;

      var $allShapes = $("[class*='shape-container--']");
    });
    document.title = "Audio Survey - Vinton Research Solutions";
    document.documentElement.scrollTop = 0;
  }
  showModal1 = () => {
    this.setState({
      visible1: true,
    });
  };

  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };
  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };
  handleCancel2 = (e) => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  showModal2 = () => {
    this.setState({
      visible2: true,
    });
  };

  handleOk2 = (e) => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  showModal3 = () => {
    this.setState({
      visible3: true,
    });
  };

  handleOk3 = (e) => {
    console.log(e);
    this.setState({
      visible3: false,
    });
  };

  handleCancel3 = (e) => {
    console.log(e);
    this.setState({
      visible3: false,
    });
  };
  showModal4 = () => {
    this.setState({
      visible4: true,
    });
  };

  handleOk4 = (e) => {
    console.log(e);
    this.setState({
      visible4: false,
    });
  };

  handleCancel4 = (e) => {
    console.log(e);
    this.setState({
      visible4: false,
    });
  };
  showModal5 = () => {
    this.setState({
      visible5: true,
    });
  };

  handleOk5 = (e) => {
    console.log(e);
    this.setState({
      visible5: false,
    });
  };

  handleCancel5 = (e) => {
    console.log(e);
    this.setState({
      visible5: false,
    });
  };
  showModal6 = () => {
    this.setState({
      visible6: true,
    });
  };

  handleOk6 = (e) => {
    console.log(e);
    this.setState({
      visible6: false,
    });
  };

  handleCancel6 = (e) => {
    console.log(e);
    this.setState({
      visible6: false,
    });
  };
  showModal7 = () => {
    this.setState({
      visible7: true,
    });
  };

  handleOk7 = (e) => {
    console.log(e);
    this.setState({
      visible7: false,
    });
  };

  handleCancel7 = (e) => {
    console.log(e);
    this.setState({
      visible7: false,
    });
  };
  showModal8 = () => {
    this.setState({
      visible8: true,
    });
  };

  handleOk8 = (e) => {
    console.log(e);
    this.setState({
      visible8: false,
    });
  };

  handleCancel8 = (e) => {
    console.log(e);
    this.setState({
      visible8: false,
    });
  };
  showModal9 = () => {
    this.setState({
      visible9: true,
    });
  };

  handleOk9 = (e) => {
    console.log(e);
    this.setState({
      visible9: false,
    });
  };
  handleCancel9 = (e) => {
    console.log(e);
    this.setState({
      visible9: false,
    });
  };
  showModal10 = () => {
    this.setState({
      visible10: true,
    });
  };

  handleOk10 = (e) => {
    console.log(e);
    this.setState({
      visible10: false,
    });
  };
  handleCancel10 = (e) => {
    console.log(e);
    this.setState({
      visible10: false,
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <ExitIntent />{" "}
        <Icon
          className="burger-menu"
          onClick={this.showDrawer}
          style={{
            color: "#E96B4A",
            fontSize: "28px",
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 3,
            border: "none",
            position: "absolute",
            boxShadow: "none",
          }}
          type="menu"
        ></Icon>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <br />
          <a href="./#home" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-packages"
          >
            Survey Packages
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#blog"
          >
            Blog
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#contact-us"
          >
            Get Started
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./faq"
          >
            FAQ
          </a>
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./videos"
          >
            Videos
          </a>
        </Drawer>{" "}
        <Header />
        <ChatBot />
        <div
          id="home"
          className="home-div"
          style={{
            padding: "30px",

            maxHeight: "100em",
            width: "100vw",
            height: "88vh",
          }}
        >
          {/* <div class="container1">
            <div class="inner-container1">
              
            </div>
        </div> */}
          <div class="container">
            <div class="inner-container">
              {" "}
              <div class="shape"></div>
            </div>
          </div>
          <Row
            className="home-row"
            style={{
              maxWidth: "80em",
              bottom: "-3vw",
              zIndex: 2,
              margin: "auto",
            }}
          >
            <Fade delay={0} duration={1500} left>
              <h1
                className="home-header"
                style={{
                  fontSize: "70px",
                  fontWeight: 700,
                  lineHeight: "0.9em",
                  textAlign: "center",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito",
                  color: "#000000",
                }}
              >
                See Our Latest
                <br />
                <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                  {" "}
                  Videos
                  <img
                    src={HorizontalLine}
                    style={{
                      width: "2.2em",
                      position: "absolute",
                      marginTop: "1em",
                      marginLeft: "-2em",
                    }}
                  ></img>{" "}
                </span>
              </h1>
            </Fade>
            <Col md={8} sm={24}>
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal1} src={Thumb1} />
                <Icon
                  onClick={this.showModal1}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Engagement Equals Results </p>
                <p class="description">
                  Captivate your audience through creative research solutions
                </p>
              </div>
            </Col>
            <Col md={8} sm={24}>
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal2} src={Thumb2} />{" "}
                <Icon
                  onClick={this.showModal2}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Breakthrough the Wall of Text</p>
                <p class="description">
                  Say goodbye to dull, borin text-only surveys.
                </p>
              </div>
            </Col>
            <Col md={8} sm={24}>
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal3} src={Thumb3} />{" "}
                <Icon
                  onClick={this.showModal3}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Successful Survey Strategies</p>
                <p class="description">
                  We use groubreaking survey technology.
                </p>
              </div>
            </Col>
            <Col md={8} sm={24}>
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal4} src={Thumb4} />{" "}
                <Icon
                  onClick={this.showModal4}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Video Content Attracts</p>
                <p class="description">
                  Did you know that video content gets 80% more views?
                </p>
              </div>
            </Col>
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal5} src={Thumb5} />

                <Icon
                  onClick={this.showModal5}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">It’s time</p>
                <p class="description">
                  It’s time to disrupt the way boring, run-of-the mill surveys
                  are done.
                </p>
              </div>
            </Col>{" "}
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal7} src={Thumb7} />

                <Icon
                  onClick={this.showModal7}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">2021</p>
                <p class="description">
                  Now is the time to collect valuable insight from your target
                  audience
                </p>
              </div>
            </Col>{" "}
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal6} src={Thumb6} />

                <Icon
                  onClick={this.showModal6}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Employee Satisfaction</p>
                <p class="description">
                  Did you know that conducting an Employee Satisfaction &
                  Engagement survey contributes to the success of your
                  organisation?
                </p>
              </div>
            </Col>{" "}
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal8} src={Thumb8} />

                <Icon
                  onClick={this.showModal8}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Get Data That Counts</p>
                <p class="description">
                  Get Answers with Our Interactive Surveys
                </p>
              </div>
            </Col>{" "}
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal9} src={Thumb9} />

                <Icon
                  onClick={this.showModal9}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Conduct a Survey</p>
                <p class="description">
                  Let Vinton Research Solutions conduct a survey for your
                  business and we’ll get valuable answers to questions that are
                  important to your business
                </p>
              </div>
            </Col>{" "}
            <Col md={8} sm={24}>
              {" "}
              <div class="video-thumb col-md-3">
                <img onClick={this.showModal10} src={Thumb10} />

                <Icon
                  onClick={this.showModal10}
                  style={{
                    borderRadius: "10em",
                    background: "rgba(233, 106, 74, 0.575)",
                    color: "white",
                    position: "absolute",
                    fontSize: "55px",
                    top: "0.7em",
                    right: "0.5em",
                  }}
                  type="play-circle"
                />
                <p class="name">Turn ‘surVeys’ into ‘surYays</p>
                <p class="description">
                  It’s time to get your survey respondent to enjoy the survey
                  journey
                </p>
              </div>
            </Col>{" "}
          </Row>{" "}
        </div>{" "}
        <Modal
          title=""
          visible={this.state.visible1}
          onOk={this.handleOk1}
          onCancel={this.handleCancel1}
        >
          <video width="100%" controls>
            <source src={Video1} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible2}
          onOk={this.handleOk2}
          onCancel={this.handleCancel2}
        >
          <video width="100%" controls>
            <source src={Video2} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible3}
          onOk={this.handleOk3}
          onCancel={this.handleCancel3}
        >
          <video width="100%" controls>
            <source src={Video3} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          title=""
          visible={this.state.visible4}
          onOk={this.handleOk4}
          onCancel={this.handleCancel4}
        >
          <video width="100%" controls>
            <source src={Video4} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible5}
          onOk={this.handleOk5}
          onCancel={this.handleCancel5}
        >
          <video width="100%" controls>
            <source src={Video5} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible6}
          onOk={this.handleOk6}
          onCancel={this.handleCancel6}
        >
          <video width="100%" controls>
            <source src={Video6} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible7}
          onOk={this.handleOk7}
          onCancel={this.handleCancel7}
        >
          <video width="100%" controls>
            <source src={Video7} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible8}
          onOk={this.handleOk8}
          onCancel={this.handleCancel8}
        >
          <video width="100%" controls>
            <source src={Video8} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible9}
          onOk={this.handleOk9}
          onCancel={this.handleCancel9}
        >
          <video width="100%" controls>
            <source src={Video9} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
        <Modal
          style={{ maxWidth: "40vw" }}
          title=""
          visible={this.state.visible10}
          onOk={this.handleOk10}
          onCancel={this.handleCancel10}
        >
          <video width="100%" controls>
            <source src={Video10} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Videos;
