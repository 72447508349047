import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "./client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import Header from "../Pages/Header";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import HomeImg from "../static/images/device-mockup.svg";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <div>
      {" "}
      <Header />{" "}
      <div
        id="home"
        className="home-div"
        style={{
          padding: "30px",

          maxHeight: "100em",
          width: "100vw",
        }}
      >
        <Row
          className="home-row"
          style={{
            maxWidth: "90em",
            bottom: "-3vw",
            margin: "auto",
          }}
        >
          <Col sm={24} md={24} style={{ margin: "auto", textAlign: "center" }}>
            <img
              style={{ borderRadius: "10em", border: "3px solid #E96B4A" }}
              src={urlFor(postData.authorImage).width(60).url()}
              alt=""
            />
            <h2
              style={{
                marginTop: "0.2em",
                color: "#E96B4A",
                fontFamily: "nunito",
                fontSize: "40px",
                lineHeight: "1em",
              }}
            >
              {postData.title}
            </h2>
            <div>
              <h3 style={{ marginTop: "-0.5em", maxWidth: "90em" }}>
                {postData.name}
              </h3>
            </div>
            <Col
              sm={24}
              md={24}
              style={{
                margin: "auto",
                textAlign: "center",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                  minWidth: "25em",
                  width: "50%",
                  height: "50%",
                  borderRadius: "0.8em",
                  borderTop: "3px solid #E96B4A",
                  borderBottom: "3px solid #E96B4A",
                }}
                src={urlFor(postData.mainImage).url()}
                alt=""
              />
            </Col>
            <div
              style={{
                margin: "auto",

                fontWeight: 100,
                color: "black",
                fontSize: "20px",
                maxWidth: "90em",
              }}
            >
              <BlockContent
                blocks={postData.body}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
              />
            </div>
            <a href="./#blog">
              <Button
                className="home-button-2"
                style={{
                  height: "2.8em",
                  width: "13em",
                  fontSize: "20px",
                  fontWeight: 500,
                  borderRadius: "3em",
                  color: "white",
                  border: "none",
                  marginTop: "1em",
                  marginBottom: "1em",
                  backgroundColor: "#E96B4A",
                  fontFamily: "nunito-regular",
                  textShadow: "none",
                  boxShadow: "none",
                }}
              >
                Back to blogs
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}
