import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import LandingPage from "./Pages/LandingPage";
import SnapQuiz from "./Pages/snap-quiz";
import MiniSurvey from "./Pages/mini-survey";
import ClassicSurvey from "./Pages/classic-survey";
import AudioSurvey from "./Pages/audio-survey";
import VideoSurvey from "./Pages/video-survey";
import FullAssessment from "./Pages/full-assessment";
import FAQ from "./Pages/faq";
import Videos from "./Pages/videos";
import SignUp from "./Pages/sign-up";
import AllPosts from "./Pages/all-posts";
import OnePost from "./Pages/one-post";
import Blogs from "./Pages/blogs";
import DecisionMaker from "./Pages/decision-maker-survey";
import ExitIntent from "./Pages/exit-intent";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {" "}
      <Route exact path="/" component={LandingPage} />
      <Route path="/snap-quiz" component={SnapQuiz} />
      <Route path="/mini-survey" component={MiniSurvey} />
      <Route path="/classic-survey" component={ClassicSurvey} />
      <Route path="/audio-survey" component={AudioSurvey} />
      <Route path="/video-survey" component={VideoSurvey} />
      <Route path="/full-assessment" component={FullAssessment} />
      <Route path="/faq" component={FAQ} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/videos" component={Videos} />
      <Route path="/blogs" component={Blogs} />{" "}
      <Route path="/decision-maker-survey" component={DecisionMaker} />
      <Route path="/exit-intent" component={ExitIntent} />
      <Route component={AllPosts} path="/all-posts"></Route>
      <Route component={OnePost} path="/:slug"></Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
