import React, { PureComponent } from "react";
import { Layout, Button, Row, Col } from "antd";
import ExitIntent from "../Pages/exit-intent";
import logoWhite from "../static/images/vpos-logo-white.svg";
import { Link } from "react-router-dom";
import logoGreen from "../static/images/w-delay-logo-green.png";

import $ from "jquery";

const Header = () => {
  return (
    <Row
      className="header"
      style={{
        margin: "auto",
        paddingTop: "1em",
        paddingBottom: "1em",
        zIndex: 999,
        maxWidth: "125em",
        width: "100%",
        backgroundColor: "#0b7b9444",
      }}
    >
      <div style={{}}>
        <Col sm={0} md={10}>
          <img
            src={logoWhite}
            alt="Logo"
            className="logo-image"
            style={{
              width: "28em",
              marginLeft: "3em",
              boxShadow: "none",
              position: "absolute",
            }}
          />
        </Col>
        <div className="header-links">
          <Col sm={0} md={3} style={{ width: "5em", paddingTop: "4em" }}>
            {" "}
            <a href="./">
              <a
                className="header-links-style"
                style={{
                  fontFamily: "nunito",
                  color: "black",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                }}
              >
                Home
              </a>{" "}
            </a>{" "}
          </Col>
          <Col sm={0} md={3} style={{ width: "7em", paddingTop: "4em" }}>
            <a href="./#survey-packages">
              <a
                className="header-links-style"
                style={{
                  fontFamily: "nunito",
                  whiteSpace: "nowrap",
                  color: "black",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                }}
              >
                Packages
              </a>
            </a>
          </Col>

          <Col sm={0} md={3} style={{ width: "5em", paddingTop: "4em" }}>
            <a
              className="header-links-style"
              style={{
                fontFamily: "nunito",
                color: "black",
                fontSize: "1.1rem",

                fontWeight: 500,
              }}
              href="./#blog"
            >
              Blogs
            </a>{" "}
          </Col>
          <Col sm={0} md={3} style={{ width: "8em", paddingTop: "4em" }}>
            <a
              className="header-links-style"
              style={{
                fontFamily: "nunito",
                color: "black",
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
              href="./#contact-us"
            >
              Contact Us
            </a>{" "}
          </Col>
          <Col sm={0} md={3} style={{ width: "4em", paddingTop: "4em" }}>
            <a
              className="header-links-style"
              style={{
                fontFamily: "nunito",
                color: "black",
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
              href="./faq"
            >
              FAQ
            </a>{" "}
          </Col>
          <Col sm={0} md={1} style={{ width: "5.5em", paddingTop: "4em" }}>
            <a
              className="header-links-style"
              style={{
                fontFamily: "nunito",
                color: "black",
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
              href="./videos"
            >
              Videos
            </a>{" "}
          </Col>
          <Col sm={0} md={1} style={{ width: "10em", paddingTop: "4em" }}>
            <a
              className="header-links-style"
              style={{
                fontFamily: "nunito",
                color: "black",
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
              target='_blank'
              href="  https://form.typeform.com/to/KR74rSjD?typeform-medium=embed-snippet"
            >
              Current Surveys

            </a>{" "}
          </Col>
          {/* <Col sm={0} md={3} style={{ paddingTop: "4em" }}>
              <a
                href="#contact-us"
                className="header-links-button-style"
                style={{
                  fontFamily: "nunito",
                  height: "2.1em",
                  width: "8em",
                  border: "none",
                  fontSize: "1.1rem",
                  fontWeight: 500,
                  borderRadius: "3em",
                  backgroundColor: "rgb(233, 107, 74)",
                  color: "black",
                  bottom: "0.35em",
                  padding: "0.5em",
                  paddingLeft: "1.5em",
                  paddingRight: "1.5em",
                  textShadow: "none",
                }}
                type="primary"
              >
                Let's Start
              </a>
              </Col> */}
        </div>
      </div>
    </Row>
  );
};

export default Header;
