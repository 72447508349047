import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Modal,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";
import Slide from "react-reveal/Fade";
import AllPosts from "../Pages/all-posts";
import HorizontalLine from "../static/images/horizontal-line.svg";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "./Header";
import ChatBot from "../Pages/chat-bot";
import styled from "styled-components";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import $ from "jquery";
const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      visible1: false,
      open: false,
    };
  }
  componentDidMount() {
    $(document).ready(function () {
      var html = "";
      for (var i = 1; i <= 50; i++) {
        html +=
          '<div class="shape-container--' +
          i +
          ' shape-animation"><div class="random-shape"></div></div>';
      }

      document.querySelector(".shape").innerHTML += html;

      var $allShapes = $("[class*='shape-container--']");
    });
    document.title = "Audio Survey - Vinton Research Solutions";
    document.documentElement.scrollTop = 0;
  }
  showModal1 = () => {
    this.setState({
      visible1: true,
    });
  };

  handleOk1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };
  handleCancel1 = (e) => {
    console.log(e);
    this.setState({
      visible1: false,
    });
  };
  handleCancel2 = (e) => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  showModal2 = () => {
    this.setState({
      visible2: true,
    });
  };

  handleOk2 = (e) => {
    console.log(e);
    this.setState({
      visible2: false,
    });
  };
  showModal3 = () => {
    this.setState({
      visible3: true,
    });
  };

  handleOk3 = (e) => {
    console.log(e);
    this.setState({
      visible3: false,
    });
  };

  handleCancel3 = (e) => {
    console.log(e);
    this.setState({
      visible3: false,
    });
  };
  showModal4 = () => {
    this.setState({
      visible4: true,
    });
  };

  handleOk4 = (e) => {
    console.log(e);
    this.setState({
      visible4: false,
    });
  };

  handleCancel4 = (e) => {
    console.log(e);
    this.setState({
      visible4: false,
    });
  };
  showModal5 = () => {
    this.setState({
      visible5: true,
    });
  };

  handleOk5 = (e) => {
    console.log(e);
    this.setState({
      visible5: false,
    });
  };

  handleCancel5 = (e) => {
    console.log(e);
    this.setState({
      visible5: false,
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        <ExitIntent />
        <Icon
          className="burger-menu"
          onClick={this.showDrawer}
          style={{
            color: "#E96B4A",
            fontSize: "28px",
            float: "right",
            top: "1.5em",
            right: "0.5em",
            zIndex: 3,
            border: "none",
            position: "absolute",
            boxShadow: "none",
          }}
          type="menu"
        ></Icon>
        <Drawer
          style={{ fontFamily: "nunito" }}
          title="Menu"
          placement={this.state.placement}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <br />
          <a href="./#home" style={{}}>
            <a
              onClick={this.onClose}
              style={{
                top: "3em",
                color: "white",
                fontSize: "25px",
                fontWeight: 500,
                fontFamily: "nunito",
              }}
            >
              Home
            </a>
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#survey-packages"
          >
            Survey Packages
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#blog"
          >
            Blog
          </a>{" "}
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./#contact-us"
          >
            Get Started
          </a>
          <br /> <br />
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./faq"
          >
            FAQ
          </a>
          <a
            onClick={this.onClose}
            style={{
              color: "white",
              fontSize: "25px",
              fontWeight: 500,
              fontFamily: "nunito",
            }}
            href="./videos"
          >
            Videos
          </a>
        </Drawer>{" "}
        <Header />
        <ChatBot />
        <div
          id="home"
          className="home-div"
          style={{
            padding: "30px",

            maxHeight: "100em",
            width: "100vw",
            height: "88vh",
          }}
        >
          {/* <div class="container1">
            <div class="inner-container1">
              
            </div>
        </div> */}
          <div class="container">
            <div class="inner-container">
              {" "}
              <div class="shape"></div>
            </div>
          </div>
          <Row
            className="home-row"
            style={{
              maxWidth: "120em",
              textAlign: "center",
              margin: "auto",
              marginTop: "3em",
            }}
          >
            <Fade delay={0} duration={1500} left>
              <h1
                className="home-header"
                style={{
                  fontSize: "70px",
                  fontWeight: 700,
                  lineHeight: "0.9em",
                  textAlign: "center",
                  color: "rgb(248, 248, 248)",
                  fontFamily: "nunito",
                  color: "#000000",
                }}
              >
                Vinton
                <span style={{ color: "#E96B4A", fontWeight: 500 }}>
                  {" "}
                  Blogs
                  <img
                    src={HorizontalLine}
                    style={{
                      width: "2.2em",
                      position: "absolute",
                      marginTop: "1em",
                      marginLeft: "-2em",
                    }}
                  ></img>{" "}
                </span>
              </h1>
            </Fade>
            <Row style={{ textAlign: "center" }}>
              <Col className="blogs-cards" md={24} sm={24}>
                <Slide duration={1000} bottom>
                  <AllPosts />
                </Slide>
              </Col>
            </Row>
          </Row>{" "}
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default Blogs;
