import React, { Component } from "react";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import ExitIntent from "./exit-intent";
import HorizontalLine from "../static/images/horizontal-line.svg";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FAQImg from "../static/images/faq.jpg";
import FAQBack from "../static/images/faq-back.png";
import FAQImg1 from "../static/images/faq1.jpg";
import HomeImgMobile from "../static/images/device-mockup-snap-audio-survey-mobile.svg";
import RocketImg from "../static/images/rocket-image.png";
import "./sales-screen.css";
import Fade from "react-reveal/Fade";
import Header from "./Header";
import ChatBot from "./chat-bot";
import styled from "styled-components";
import $ from "jquery";
const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      FormChange: false,
      visible: false,
      open: false,
    };
  }
  componentDidMount() {
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    $(document).ready(function () {
      var html = "";
      for (var i = 1; i <= 50; i++) {
        html +=
          '<div class="shape-container--' +
          i +
          ' shape-animation"><div class="random-shape"></div></div>';
      }

      document.querySelector(".shape").innerHTML += html;

      var $allShapes = $("[class*='shape-container--']");
    });
    document.title = "Audio Survey - Vinton Research Solutions";
    document.documentElement.scrollTop = 0;
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <React.Fragment>
        {" "}
        <div class="container" style={{ display: "none" }}>
          <div class="inner-container">
            {" "}
            <div class="shape"></div>
          </div>
        </div>
        <iframe
          src="https://form.typeform.com/to/aiWMLJT9?typeform-medium=embed-snippet"
          style={{ width: "100vw", height: "100vh" }}
          title="Iframe Example"
        ></iframe>
      </React.Fragment>
    );
  }
}

export default FAQ;
