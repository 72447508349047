import React, { Component } from "react";

import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";
import AllPosts from "../Pages/all-posts";
import OnePost from "../Pages/one-post";
import { Link } from "react-router-dom";
import { Modal as SuccessModal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import logoWhite from "../static/images/v-logo-white.png";
import HorizontalLine from "../static/images/horizontal-line.svg";
import MobileSide from "../static/images/mobile-side-img.svg";
import Card1 from "../static/images/card-1.png";
import Card3 from "../static/images/card-3.png";
import Card4 from "../static/images/card-4.png";
import Card5 from "../static/images/card-5.png";
import Card6 from "../static/images/card-6.png";
import Card7 from "../static/images/card-7.png";
import Card2 from "../static/images/card-2.png";
import VideoIcon from "../static/images/video-icon.png";
import MessageIcon from "../static/images/message-icon.png";
import TabsIcon from "../static/images/tabs-icon.png";
import HomeImg from "../static/images/device-mockup.svg";
import HomeImgMobile from "../static/images/device-mockup-mobile-home.svg";
import WaveImg from "../static/images/wave-back.svg";
import FooterImg from "../static/images/footer-image.png";
import FooterImgMobile from "../static/images/footer-image-mobile.png";
import { useSpring, animated } from "react-spring";
import "./sales-screen.css";
import Slide from "react-reveal/Fade";
import Header from "../Pages/Header";
import ChatBot from "../Pages/chat-bot";
import styled, { css, keyframes } from "styled-components";
import $ from "jquery";
const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse1 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse2 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse3 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse4 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const shadowPulse5 = keyframes`
  0% {
    box-shadow: 0 0 0 0px #E96B4A;
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;
const StyledButton = styled(Button)`
  animation: ${shadowPulse} 1s infinite;
`;
const StyledButton1 = styled(Button)`
  animation: ${shadowPulse1} 1s infinite;
`;
const StyledButton2 = styled(Button)`
  animation: ${shadowPulse2} 1s infinite;
`;
const StyledButton3 = styled(Button)`
  animation: ${shadowPulse3} 1s infinite;
`;
const StyledButton4 = styled(Button)`
  animation: ${shadowPulse4} 1s infinite;
`;
const StyledButton5 = styled(Button)`
  animation: ${shadowPulse5} 1s infinite;
`;
const FormItem = Form.Item;
const { Option } = Select;
const ProjectCards = styled(Card)`
  background-color: transparent;
  margin-bottom: 3em;
  top: -0em;
  margin: auto;
  text-align: center;
  height: 18em;
  width: 22em;
  border-radius: 1em;
  box-shadow: none;
  border: 0px solid #2482db;
  @media (max-width: 1000px) {
  }
  @media (max-width: 770px) {
  }
`;
const StyledInputNumber = styled(InputNumber)`
  border-top: 0px solid #d9d9d9;
  border-left: 0px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-bottom: 1px solid #158577;

  color: black;
  background-color: transparent;
  border: 0;

  outline: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
`;
const StyledSelect = styled(Select)`
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    font-weight: 9000;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -6px;
    color: white;
    font-size: 17px;
    line-height: 1;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    margin-left: -8px;
    height: 20px;

    overflow: hidden;
    color: rgba(255, 255, 255, 0.753);
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
  }
  .ant-select-selection {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    padding-left: 1.8em !important;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum", "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 55px !important;
    padding: 4px 11px;
    color: white;
    box-shadow: none;
    line-height: 1.5;
    background-color: transparent;
    background-image: none;
    border: 2px solid white;
    border-radius: 50px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  :placeholder {
    color: red !important;
  }
`;
const StyledInput = styled(Input)`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-left: 1.8em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 55px !important;
  padding: 4px 11px;
  color: white;
  box-shadow: none;
  line-height: 1.5;
  background-color: transparent;
  background-image: none;
  border: 2px solid white;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  :placeholder {
    color: red !important;
  }
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
function hasErrors1(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`;
const trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

function CardAn() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <div
      class="container"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div style={{ transform: props.xy.interpolate(trans1) }}>
        <img
          className="home-image"
          src={HomeImg}
          style={{
            zIndex: 9,
          }}
        ></img>

        <img
          className="home-image-mobile"
          src={HomeImgMobile}
          style={{
            zIndex: 9,
          }}
        ></img>
      </animated.div>
    </div>
  );
}
$(document).ready(function () {
  var html = "";
  for (var i = 1; i <= 50; i++) {
    html +=
      '<div class="shape-container--' +
      i +
      ' shape-animation"><div class="random-shape"></div></div>';
  }

  document.querySelector(".shape").innerHTML += html;

  var $allShapes = $("[class*='shape-container--']");
});
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: "left",
      inputValue: 1,
      FormChange: false,
      visible: false,
      open: false,
      successModal: false,
    };
    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    $("a.close").click(function () {
      $(".lightbox").slideUp();
    });
    document.documentElement.scrollTop = 0;
  }
  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    });
  };
  onChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.onSubmit(values);
      }
    });
  };
  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.reload(false);
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onSubmit = (values) => {
    const MSG = {
      to: ["peterv@vinton.co.za"],
      from: "info@venturepoint.co.za",
      subject: "New newsletter request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="white">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/6PdhcHK/v-logo-white.png" width="300" height="100" style="display: block; width:195px; max-width: 195px; min-width: 195px;border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #E96B4A; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:#E96B4A;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Newsletter Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: #E96B4A;line-height:135%;">You have received a new newsletter request from <br /> the Vinton Research Solutions website</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>

        <!-- MODULE ROW // -->
        
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br /><br /><br /><br /><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name1}<br />
                               <strong> Company </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${values.company1}<br />
                               <strong> Email </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.email1}<br />
                      
                             <br /><br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="white" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2020 Vinton Research Solutions</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        successModal: true,
        submitDisabled: true,
        name1: "",
        company1: "",
        email1: "",
      });
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { inputValue } = this.state;
    return (
      <React.Fragment>
        <ExitIntent />
        <div class="container" style={{ display: "none" }}>
          <div class="inner-container">
            <div class="shape"></div>
          </div>
        </div>
        <img
          className="big-wave-image"
          src={WaveImg}
          style={{
            position: "absolute",
            width: "100vw",
            minWidth: "130em",
            left: 0,
          }}
        ></img>
        <Row
          className="sign-up-section"
          style={{
            textAlign: "center",
            margin: "auto",
            marginTop: "14em",
          }}
        >
          <h1
            className="center-header"
            style={{
              fontSize: "55px",
              fontWeight: 700,
              lineHeight: "1em",
              color: "white",
              fontFamily: "nunito",
            }}
          >
            Sign up for our survey special offers,
            <br /> tips & newsletter{" "}
            <img
              src={HorizontalLine}
              style={{
                filter: "brightness(10.5)",
                width: "2.8em",
                position: "absolute",
                marginTop: "1.15em",
                marginLeft: "-2.5em",
              }}
            ></img>{" "}
          </h1>{" "}
          <p
            style={{
              marginTop: "0.5em",
              color: "white",
              fontSize: "23px",
              fontWeight: 100,

              textAlign: "center",
            }}
          >
            Fill in the form below to be the first to know about all things
            Vinton.
          </p>{" "}
          <Form onSubmit={this.handleSubmit}>
            <Row
              gutter={24}
              style={{ margin: "auto", maxWidth: "80em", marginTop: "3em" }}
            >
              <Col sm={24} md={8}>
                <FormItem className=".form-item-style1" style={{}}>
                  {getFieldDecorator("name1", {
                    rules: [{ required: true, message: "Name required" }],
                  })(
                    <StyledInput
                      style={{
                        width: "100%",

                        minWidth: "10.5em",
                      }}
                      id="standard-basic"
                      placeholder="Name"
                    />
                  )}{" "}
                </FormItem>
              </Col>{" "}
              <Col sm={24} md={8}>
                <FormItem
                  className=".form-item-style1"
                  style={{
                    marginTop: "0em",
                  }}
                >
                  {getFieldDecorator("company1", {
                    rules: [{ required: true, message: "Company required" }],
                  })(
                    <StyledInput
                      style={{
                        width: "100%",
                        minWidth: "10.5em",
                      }}
                      id="standard-basic"
                      placeholder="Company"
                    />
                  )}{" "}
                </FormItem>
              </Col>
              <Col sm={24} md={8}>
                <FormItem
                  className=".form-item-style1"
                  style={{
                    marginTop: "0em",
                  }}
                >
                  {getFieldDecorator("email1", {
                    rules: [{ required: true, message: "Email required" }],
                  })(
                    <StyledInput
                      style={{
                        width: "100%",

                        minWidth: "10.5em",
                      }}
                      id="standard-basic"
                      placeholder="Email"
                    />
                  )}{" "}
                </FormItem>
              </Col>
            </Row>{" "}
            <Row
              style={{
                margin: "auto",
                textAlign: "center",
              }}
            >
              <button
                disabled={hasErrors(getFieldsError())}
                htmlType="submit"
                style={{
                  border: "none",
                  fontSize: "20px",
                  fontWeight: 700,
                  border: "none",
                  marginTop: "1em",
                  background: "white",
                  color: "rgb(233, 107, 74)",
                  borderRadius: "2em",
                  width: "13em",
                  minWidth: "10em",
                  height: "2.9em",
                  paddingTop: "0px",
                  fontFamily: "nunito-regular",
                  textShadow: "none",
                  boxShadow: "none",
                }}
                className="form-item-button-style"
                type="primary"
              >
                Let's Engage
              </button>
            </Row>
          </Form>{" "}
        </Row>{" "}
        <SuccessModal
          open={this.state.successModal}
          onClose={this.handleCloseSuccessModal}
          center
        >
          <img src={logoWhite} alt="Logo" style={{}} />

          <h3
            style={{
              marginTop: "1em",
              color: "#E96B4A",
              textAlign: "center",
            }}
          >
            Thanks for engaging with us… remember Engagement Equals Results.
            <br />
          </h3>
        </SuccessModal>
      </React.Fragment>
    );
  }
}
const WrappedHorizontalSignUpForm = Form.create({ name: "horizontal_login" })(
  SignUp
);
export default WrappedHorizontalSignUpForm;
