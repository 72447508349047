import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./client";
import {
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Icon,
  Form,
  Input,
  Drawer,
  Slider,
  InputNumber,
} from "antd";
import ExitIntent from "../Pages/exit-intent";
export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <Row style={{ marginTop: "0em" }}>
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Link to={"/" + post.slug.current} key={post.slug.current}>
              <Col style={{ padding: "0.5em" }} sm={24} md={8} key={index}>
                <Button
                  className="home-button-2"
                  style={{
                    height: "2.6em",
                    width: "9em",
                    fontSize: "20px",
                    fontWeight: 500,
                    borderRadius: "3em",
                    color: "#E96B4A",
                    border: "2px solid #E96B4A",
                    position: "inherit",
                    bottom: "-15em",
                    backgroundColor: "rgba(255, 255, 255, 0.821)",
                    fontFamily: "nunito",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  Read More
                </Button>
                <span>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "23em",
                      zIndex: -9999,
                      borderRadius: "0.5em",
                    }}
                    src={post.mainImage.asset.url}
                    alt=""
                  />{" "}
                </span>

                <h2
                  className="main-blogs-header"
                  style={{
                    padding: "3em",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",
                    paddingTop: "2em",
                    top: "-11em",
                    background:
                      "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "11.5em",
                    fontFamily: "nunito",
                    lineHeight: "1em",
                  }}
                >
                  {post.title}
                </h2>
              </Col>
            </Link>
          ))}
      </Row>
    </div>
  );
}
